<template>
  <div
    dir="ltr"
    :class="[
      suggestedBlockClassName,
      { disabled: !enableSuggestedAssessmentButton }
    ]"
  >
    <div class="suggested-assessment-wrapper" @click="startAssessment">
      <div dir="auto" class="suggested-assessment-side">
        <div class="suggested-assessment-text">
          <span>Take the placement test to discover your reading level. </span>
        </div>
        <div class="suggested-assessment-button">
          <BaseButton color="primary" elevation="0" large :disabled="!isOnline">
            Start test
          </BaseButton>
        </div>
      </div>
      <button
        v-if="closeButtonVisible"
        class="suggested-assessment-close-btn"
        @click.stop.prevent="closeBanner"
      >
        <BaseSpriteIcon icon-name="ico-close" />
      </button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import AppStateEnum from '@/enums/AppStateEnum';

export default {
  name: 'SuggestedAssessmentButton',
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  props: {
    closeButtonVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      suggestedBlockClassName: AppConstantsUtil.SUGGESTED_BLOCK_CLASS
    };
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    enableSuggestedAssessmentButton() {
      const isSuggestedAssessmentButtonVisible = this.$store.getters[
        'AssessmentStore/isSuggestedAssessmentButtonVisible'
      ];
      return isSuggestedAssessmentButtonVisible;
    }
  },
  methods: {
    startAssessment() {
      if (this.isOnline) {
        this.$router.push({ name: AppStateEnum.ASSESSMENT });
      }
    },
    closeBanner() {
      this.$store.commit('UserStore/setAssessmentBannerVisibility', false);
    }
  }
};
</script>

<style lang="less" src="./SuggestedBanner.less"></style>
