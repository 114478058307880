<template>
  <div class="search-banner">
    <BaseButton
      elevation="0"
      text
      x-small
      class="close-button pa-0 ps-1"
      :aria-label="$t('Accessibility.searchBanner.close')"
      color="#fff"
      @click="closeBanner"
    >
      <span>Close</span>
      <BaseSpriteIcon icon-name="ico-close" />
    </BaseButton>
    <BaseTextField
      rounded
      clearable
      hide-details
      prepend-inner-icon="$searchIcon"
      :placeholder="$t('SearchBanner.searchField.placeholder')"
      class="mt-3"
      clear-icon="$crossIcon"
      @click="textInputHandler"
      @keyup.enter="textInputHandler"
    />
    <div class="tips-block mt-3">
      <BaseSpriteIcon icon-name="ico-lightbulb-line" />
      <span class="tips-title">Tip:</span>
      <Transition>
        <span v-if="currentTip" class="tip" v-html="currentTip"></span>
      </Transition>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import PromiseUtil from '@/services/utils/PromiseUtil';
import AppStateEnum from '@/enums/AppStateEnum';

const DISPLAY_INTERVAL = 9 * 1000;

export default {
  name: 'SearchBanner',
  components: {
    BaseButton,
    BaseTextField,
    BaseSpriteIcon
  },
  data() {
    const highlightClass = 'tip-highlight';
    return {
      show: true,
      tipsInterval: null,
      currentTipIndex: 0,
      tips: [
        `Use <span class="${highlightClass}"><b>( )</b></span>
         to limit by <span class="${highlightClass}"><b>book, author</b></span>
         <b>or</b> <span class="${highlightClass}"><b>collection</b></span>`,
        `Use  <span class="${highlightClass}"><b>“ “</b></span>
         to match <span class="${highlightClass}"><b>exact word</b></span>
          <b>or</b> <span class="${highlightClass}"><b>phrase</b></span>`,
        `Use “<span class="${highlightClass}"><b>-</b></span>“
        (sign minus) to <span class="${highlightClass}"><b>exclude</b></span>
        results with <span class="${highlightClass}"><b>- word</b></span>`
      ]
    };
  },
  computed: {
    currentTip() {
      return this.tips[this.currentTipIndex];
    }
  },
  mounted() {
    this.startTips();
  },
  destroyed() {
    this.stopTips();
  },
  methods: {
    startTips() {
      this.stopTips();
      this.tipsInterval = setInterval(async () => {
        const currentIndex = this.currentTipIndex;
        this.currentTipIndex = -1;
        await PromiseUtil.wait(2000);
        this.currentTipIndex = currentIndex + 1;
        this.currentTipIndex = this.currentTipIndex % this.tips.length;
      }, DISPLAY_INTERVAL);
    },
    stopTips() {
      clearInterval(this.tipsInterval);
    },
    textInputHandler() {
      this.$router.push({ name: AppStateEnum.SEARCH });
    },
    closeBanner() {
      this.$store.commit('UserStore/setSearchBannerVisibility', false);
    }
  }
};
</script>

<style lang="less" src="./SearchBanner.less"></style>
